import React, { useState } from "react";
import { Route, Routes, Link, Outlet } from "react-router-dom";

import Navbar from "./components/Navbar";
import Homepage from "./pages/Homepage";
import Carrieres from "./pages/Carrieres";
import Produits from "./pages/Produits";
import Employes from "./pages/Employes";
import Entreprise from "./pages/Entreprise";
import Messagerie from "./pages/Messagerie";

const App = () => {
  const [isLoggedInLvl1, setIsLoggedInLvl1] = useState(localStorage.getItem('connexion') === 'lvl1');
  const [isLoggedInLvl2, setIsLoggedInLvl2] = useState(localStorage.getItem('connexion') === 'lvl2');
  const [refreshFlag, setRefreshFlag] = useState(false);


  const handleLogout = () => {
    // Inverser le drapeau de rafraîchissement pour déclencher le rendu
    setIsLoggedInLvl1(false);
    setIsLoggedInLvl2(false)
    setRefreshFlag(prevFlag => !prevFlag);
  };

  return (
    // <div className={`navbarContainer ${isLoggedInLvl2 ? 'strong-tilt-move-shake' : ''}`}>
    <div className={`navbarContainer`}>
      {/* {isLoggedInLvl2 && <div className="red-overlay"></div>} */}
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Layout 
                handleLogout={handleLogout} 
                isLoggedInLvl1={isLoggedInLvl1} 
                isLoggedInLvl2={isLoggedInLvl2} 
                refreshFlag={refreshFlag} 
              />
              <Outlet />
            </>
          }
        >
          <Route index element={<Homepage />} />
          <Route path="carrieres" element={<Carrieres />} />
          <Route path="produits" element={<Produits />} />
          <Route
            path="employes"
            element={
              <Employes
                isLoggedInLvl1={isLoggedInLvl1}
                setIsLoggedInLvl1={setIsLoggedInLvl1}
                isLoggedInLvl2={isLoggedInLvl2}
                setIsLoggedInLvl2={setIsLoggedInLvl2}
              />
            }
          />
          <Route path="entreprise" element={<Entreprise />} />
          <Route 
            path="messagerie" 
            element={
              <Messagerie 
                isLoggedInLvl1={isLoggedInLvl1} 
                isLoggedInLvl2={isLoggedInLvl2} 
                refreshFlag={refreshFlag} 
              />} 
          />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
};

function Layout({ handleLogout, isLoggedInLvl1, isLoggedInLvl2 }) {
  return (
    <div>
      <Navbar isLoggedInLvl1={isLoggedInLvl1} isLoggedInLvl2={isLoggedInLvl2} onLogout={handleLogout} />
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
