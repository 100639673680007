import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Messagerie = ({ isLoggedInLvl1, isLoggedInLvl2, refreshFlag }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedInLvl1 && !isLoggedInLvl2) {
      navigate("/Employes")
    }
  }, [refreshFlag, isLoggedInLvl1, isLoggedInLvl2, navigate]);

  return (
    <div className={`full-page-container ${isLoggedInLvl2 ? 'strong-tilt-move-shake' : ''}`}>
      {isLoggedInLvl1 && isLoggedInLvl1 === true &&
        <div className="messagerie-container">
          <div className="sidebar">
            <ul className="inbox-list">
              <li className="inbox-item active">
                <div className="inbox-item-title">Inbox</div>
                <div className="inbox-item-count">5</div>
              </li>
            </ul>
          </div>
          <div className="main">
            <div className="STOP">STOP - Pour le moment on a pas encore développé la suite. Ca s'arrete là. Merci d'avoir testé, faites nous vos retours !</div>
          </div>
        </div>
      }
      {isLoggedInLvl2 && isLoggedInLvl2 === true &&
        <div>TEST LVL 2</div>
      }
      {!isLoggedInLvl1 && !isLoggedInLvl2 &&
        <div>Connecte toi</div>
      }

    </div>
  );
};

export default Messagerie;
