import React from 'react';

const Produits = () => (
  <div className='full-page-container'>
      <div className='landing-area-products'>
        <div className='cta-area-products'>
          <div className='row-container-product'>
            <div className='label-icon-title'></div>
          
            <div className='product-title'>lorem ipsum</div>
          </div>
          <div className='product-subtitle'>Product subtitle lorem ipsum dolor sic amet</div>
            
        </div>
        <div className='flex-labels'>
        <div className='label-container'>
            <div className='label-icon'></div>
            <div className='col-container-product'>
              <div className='label-title'>Titre</div>
              <div className='label-text'>Lorep Ipsum</div>
            </div>
          </div>
          <div className='label-container'>
            <div className='label-icon'></div>
            <div className='col-container-product'>
              <div className='label-title'>Titre</div>
              <div className='label-text'>Lorep Ipsum</div>
            </div>
          </div>
          <div className='label-container'>
            <div className='label-icon'></div>
            <div className='col-container-product'>
              <div className='label-title'>Titre</div>
              <div className='label-text'>Lorep Ipsum</div>
            </div>
          </div>
          <div className='label-container'>
            <div className='label-icon'></div>
            <div className='col-container-product'>
              <div className='label-title'>Titre</div>
              <div className='label-text'>Lorep Ipsum</div>
            </div>
          </div>
          <div className='label-container'>
            <div className='label-icon'></div>
            <div className='col-container-product'>
              <div className='label-title'>Titre</div>
              <div className='label-text'>Lorep Ipsum</div>
            </div>
          </div>
        </div>
      </div>
      <div className='products-area'>
        <div className='product-main-title'>Product title</div>
        <div className='products-flex-container'>
          <div className='product-col'>
            <div className='product-tile'>
              <div className='product-tile-inside'>
                <span>Tile text</span>
              </div>
            </div>
            <div className='group'>
              <div className='products-title'>Title</div>
              <div className='line'></div>
              <div className='product-text'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lobortis aliquam ultricies. Vestibulum erat ex, pellentesque id elementum eu, pulvinar sed metus. Donec eget nibh efficitur, fringilla dui et, ornare metus. 
              </div>
            </div>
            <div className='group'>
              <div className='products-title'>Title 2</div>
              <div className='line'></div>
              <div className='product-text'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lobortis aliquam ultricies. Vestibulum erat ex, pellentesque id elementum eu, pulvinar sed metus. Donec eget nibh efficitur, fringilla dui et, ornare metus. 
              </div>
            </div>
          </div>

          <div className='product-col'>
            <div className='product-tile'>
              <div className='product-tile-inside'>
                <span>Tile text</span>
              </div>
            </div>
            <div className='group'>
              <div className='products-title'>Title</div>
              <div className='line'></div>
              <div className='product-text'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lobortis aliquam ultricies. Vestibulum erat ex, pellentesque id elementum eu, pulvinar sed metus.
              </div>
            </div>
            <div className='group'>
            
              <div className='products-title'>Title 2</div>
              <div className='line'></div>
              <div className='product-text'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lobortis aliquam ultricies. Vestibulum erat ex, pellentesque id elementum eu, pulvinar sed metus. 
              </div>
            </div>
          </div>

          <div className='product-col'>
            <div className='product-tile'>
              <div className='product-tile-inside'>
                <span>Tile text</span>
              </div>
            </div>
            <div className='group'>
              <div className='products-title'>Title</div>
              <div className='line'></div>
              <div className='product-text'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lobortis aliquam ultricies. Vestibulum erat ex, pellentesque id elementum eu, pulvinar sed metus.
              </div>
            </div>
            <div className='group'>
            
              <div className='products-title'>Title 2</div>
              <div className='line'></div>
              <div className='product-text'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lobortis aliquam ultricies. Vestibulum erat ex, pellentesque id elementum eu, pulvinar sed metus. 
              </div>
            </div>
          </div>

          <div className='product-col'>
            <div className='product-tile'>
              <div className='product-tile-inside'>
                <span>Tile text</span>
              </div>
            </div>
            <div className='group'>
              <div className='products-title'>Title</div>
              <div className='line'></div>
              <div className='product-text'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lobortis aliquam ultricies. Vestibulum erat ex, pellentesque id elementum eu, pulvinar sed metus.
              </div>
            </div>
            <div className='group'>
            
              <div className='products-title'>Title 2</div>
              <div className='line'></div>
              <div className='product-text'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lobortis aliquam ultricies. Vestibulum erat ex, pellentesque id elementum eu, pulvinar sed metus. 
              </div>
            </div>
          </div>

          <div className='product-col'>
            <div className='product-tile'>
              <div className='product-tile-inside'>
                <span>Tile text</span>
              </div>
            </div>
            <div className='group'>
              <div className='products-title'>Title</div>
              <div className='line'></div>
              <div className='product-text'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lobortis aliquam ultricies. Vestibulum erat ex, pellentesque id elementum eu, pulvinar sed metus.
              </div>
            </div>
            <div className='group'>
            
              <div className='products-title'>Title 2</div>
              <div className='line'></div>
              <div className='product-text'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lobortis aliquam ultricies. Vestibulum erat ex, pellentesque id elementum eu, pulvinar sed metus. 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
);

export default Produits;
