import React from 'react';

function Entreprise() {
  return (
    <div className='full-page-container'>
      <div>Entreprise</div>
      <p>Bienvenue sur la page Entreprise.</p>
    </div>
  );
}

export default Entreprise;
