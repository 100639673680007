import React from 'react';

function Carrieres() {
  return (
    <div className="full-page-container"> 
      <div>Carrières</div>
      <p>Bienvenue sur la page Carrières.</p>
    </div>
  );
}

export default Carrieres;
