import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

const Employes = ({ isLoggedInLvl1, setIsLoggedInLvl1, isLoggedInLvl2, setIsLoggedInLvl2 }) => {
  const today = new Date("2023-05-05")
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [date, setDate] = useState(today);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const maxDate = new Date("2030-12-31");

  useEffect(() => {
    isLoggedInLvl1 && navigate("/messagerie", { state: { isLoggedInLvl1: true, isLoggedInLvl2: false } });
  }, [isLoggedInLvl1, navigate]);

  useEffect(() => {
    isLoggedInLvl2 && navigate("/messagerie", { state: { isLoggedInLvl1: false, isLoggedInLvl2: true } });
  }, [isLoggedInLvl2, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(date)
    console.log(date?.getDate())
    console.log(date?.getMonth())
    console.log(date?.getFullYear())
    if (name.toUpperCase() === "TRISTAN PRIGENT" && id === "6794234098" && date?.getDate() === 8 && date?.getMonth() + 1 === 5 && date?.getFullYear() === 2023) {
      setIsLoggedInLvl1(true)
      setIsLoggedInLvl2(false)
      localStorage.setItem("connexion", "lvl1");
      navigate("/messagerie", { state: { isLoggedInLvl1: true, isLoggedInLvl2: false } });


    } else if (name.toUpperCase() === "TEST" && id === "123") {
      setIsLoggedInLvl1(false)
      setIsLoggedInLvl2(true)
      localStorage.setItem("connexion", "lvl2");
      navigate("/messagerie", { state: { isLoggedInLvl1: false, isLoggedInLvl2: true } });
      return;
    } else if (name.toUpperCase() !== "TRISTAN PRIGENT" || id !== "6794234098") {
      setIsLoggedInLvl1(false);
      setErrorMessage("Identifiants Incorrects");
    } else if (name.toUpperCase() === "TRISTAN PRIGENT" && id === "6794234098" && !(date?.getDate() === 8 && date?.getMonth() + 1 === 5 && date?.getFullYear() === 2023)) {
      setIsLoggedInLvl1(false);
      setErrorMessage("Vos identifiants seront prochainement activés");
    }
  };

  return (
    <div className='full-page-container'>

      <div className="employes">
        <div>Connexion employé</div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nom</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="id">ID</label>
            <input
              type="text"
              id="id"
              value={id}
              onChange={(event) => setId(event.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="date">Date</label>
            <br />
            <DatePicker
              autoFocus={true}
              selected={date}
              onChange={(date) => setDate(date)}
              dateFormat="dd/MM/yyyy"
              required
              maxDate={maxDate}
            />
          </div>
          <button type="submit">Se connecter</button>
        </form>
      </div>
    </div>
  );
};

export default Employes;
