import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import logo from '../assets/logo.png';
import logoutImg from '../assets/logout.png';

const drawerWidth = 240;
const navItems = ['Produits', 'Entreprise', 'Carrieres', 'Employes'];

function Navbar({ onLogout, isLoggedInLvl1, isLoggedInLvl2, window }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const logout = () => {
    localStorage.removeItem('connexion')
    onLogout()
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Link className="CompanyTitle" to={`/`}> <img className="logo-nav" src={logo} alt="Logo" />GenoCorp</Link>
      </Typography>
      <Divider />
      <List>
        <div className='flexWrapperMobile'>
          {navItems.map((item) => (
            <ListItem key={item} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <Link className={`testMobile ${location.pathname === `/${item}` ? 'active' : ''}`} to={`/${item}`}>{item}</Link>
              </ListItemButton>
            </ListItem>
          ))}
        </div>
        {(isLoggedInLvl1 || isLoggedInLvl2) && (
          <ListItem key='logoutMobile' disablePadding>
            <ListItemButton className="testMobile" sx={{ textAlign: 'center' }} key='logoutMobile' onClick={logout}>
              <img className="logoutIcon" src={logoutImg} alt="Logo" />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <img className="logo-nav" src={logo} alt="Logo" />
          <Typography
            className='logoTitle'
            variant="h4"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <Link className="CompanyTitle" to={`/`}>GenoCorp</Link>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: '#fff' }}>
                <Link className={`navBarItems ${location.pathname === `/${item}` ? 'active' : ''}`} to={`/${item}`}>{item}</Link>
              </Button>

            ))}
          </Box>
          {(isLoggedInLvl1 || isLoggedInLvl2) && (
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <Button key='logout' sx={{ color: '#000' }} onClick={logout}>
                <img className="logoutIcon" src={logoutImg} alt="Logo" />
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          isLoggedInLvl1={isLoggedInLvl1}
          isLoggedInLvl2={isLoggedInLvl2}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navbar;