import React from 'react';

function Homepage() {
  return (
    <div className='full-page-container'>
      <div className='landing-area'>
        <div className='cta-area'>
          <div className='main-title'>GenoCorp</div>
          <div className='big-subtitle'>Santé, génétique, longévité. Redéfinissons la réalité.</div>
          <div className='green-line'></div>
          <div className='small-subtitle'>GenoCorp, architecte de votre bien-être, vous convie à une aventure scientifique exclusive où la santé, la génétique et la longévité se conjuguent harmonieusement, créant une réalité sur mesure pour chaque individu.</div>
        </div>
        
      </div>
      <div className='col-container'>
        <div className='columns'>
          <div className='col'>
            <div className='col-title'>Médecine</div>
            <div className='col-subtitle'>Sous Titre</div>
            <div className='col-txt'>Proin molestie tortor non dolor tincidunt, sed sagittis nunc egestas. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam erat volutpat. Cras molestie tempus metus, sit amet accumsan nisi ultrices in. Integer nec commodo velit, ac elementum mi. Quisque ligula massa, auctor efficitur rutrum in, tempor vitae lacus. Maecenas rhoncus nisl id sagittis congue. Integer eu varius sem, id dapibus leo. Nunc gravida ornare magna, a commodo tellus eleifend sagittis. Aliquam erat volutpat. Suspendisse nisi mauris, placerat nec leo sed, luctus mollis odio.</div>
            <div className='col-link'><span>Voir plus</span></div>
          </div>
          <div className='col'>
          <div className='col-title'>Pharmacologie</div>
            <div className='col-subtitle'>Sous Titre</div>
            <div className='col-txt'>Proin molestie tortor non dolor tincidunt, sed sagittis nunc egestas. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam erat volutpat. Cras molestie tempus metus, sit amet accumsan nisi ultrices in. Integer nec commodo velit, ac elementum mi. Quisque ligula massa, auctor efficitur rutrum in, tempor vitae lacus. Maecenas rhoncus nisl id sagittis congue. Integer eu varius sem, id dapibus leo. Nunc gravida ornare magna, a commodo tellus eleifend sagittis. Aliquam erat volutpat. Suspendisse nisi mauris, placerat nec leo sed, luctus mollis odio.</div>
            <div className='col-link'><span>Voir plus</span></div>
          </div>
          <div className='col'>
          <div className='col-title'>Génétique</div>
            <div className='col-subtitle'>Sous Titre</div>
            <div className='col-txt'>Proin molestie tortor non dolor tincidunt, sed sagittis nunc egestas. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam erat volutpat. Cras molestie tempus metus, sit amet accumsan nisi ultrices in. Integer nec commodo velit, ac elementum mi. Quisque ligula massa, auctor efficitur rutrum in, tempor vitae lacus. Maecenas rhoncus nisl id sagittis congue. Integer eu varius sem, id dapibus leo. Nunc gravida ornare magna, a commodo tellus eleifend sagittis. Aliquam erat volutpat. Suspendisse nisi mauris, placerat nec leo sed, luctus mollis odio.</div>
            <div className='col-link'><span>Voir plus</span></div>
          </div>
          <div className='col'>
          <div className='col-title'>Longévité</div>
            <div className='col-subtitle'>Sous Titre</div>
            <div className='col-txt'>Proin molestie tortor non dolor tincidunt, sed sagittis nunc egestas. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam erat volutpat. Cras molestie tempus metus, sit amet accumsan nisi ultrices in. Integer nec commodo velit, ac elementum mi. Quisque ligula massa, auctor efficitur rutrum in, tempor vitae lacus. Maecenas rhoncus nisl id sagittis congue. Integer eu varius sem, id dapibus leo. Nunc gravida ornare magna, a commodo tellus eleifend sagittis. Aliquam erat volutpat. Suspendisse nisi mauris, placerat nec leo sed, luctus mollis odio.</div>
            <div className='col-link'><span>Voir plus</span></div>
          </div>

        </div>
      </div>
      
    </div>
  );
}

export default Homepage;
